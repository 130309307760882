import React from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
const RadioBox = ({ options = [], name, handleChange, selected, label }) => {

    return ( 
        
        <div className={`${(options.length > 2) ? 'mortgage-boxes' : 'mortgage-box'}`}>
            <Form.Label> {label}</Form.Label>
            <div className={`${(options.length > 2) ? 'mortgage-type' : ' btn-wrapper '}`} >
            <Row>
                {options.map((opt) => {

                    return (
                        <Col md={6}>
                        <label class="btn-container">

                            <Form.Control type="radio" name={name} value={opt} onChange={handleChange} checked={selected === opt} />
                            <span class="checkmark ">{opt}</span>
                        </label>
                        </Col>
                    )

                })}
                </Row>
            </div>
        </div >
           
    )

}
export default RadioBox;